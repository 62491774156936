import api from '@/api/positions'
import { notify } from '@/helpers/notifyHelper'

export default {
	name: 'Create',
	data () {
		return {
			valid: false,
			formData: {
				name: '',
				department: null,
				location: null,
				majorDuties: null,
				workTerm: null,
				qualifications: [''],
				advantages: [''],
				description: null,
				urlName: null,
				summary: null,
				metaTitle: '',
				metaDescription: '',
				metaKeywords: ''
			},
			terms: [
				'Part leave',
				'Full time',
				'Flex time',
				'Salary',
				'Pay slip'
			]
		}
	},
	methods: {
		addQualification (index) {
			if (index === this.formData.qualifications.length -1) {
				this.formData.qualifications.push('')
			} else {
				this.formData.qualifications.splice(index, 1)
			}
		},
		addAdvantage (index) {
			if (index === this.formData.advantages.length -1) {
				this.formData.advantages.push('')
			} else {
				this.formData.advantages.splice(index, 1)
			}
		},
		create () {
			if (this.$refs.form.validate()) {
				var regExpression = /^[a-zA-Z0-9-]*$/
				if (!regExpression.test(this.formData.urlName)) {
					notify(this, 'error', 'Link may be string and - ')
					return
				}

				api.create(this, this.formData)
					.then(() => {
						this.$router.push({name: 'Positions'})
					})
			}
		}
	}
}

